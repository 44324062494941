import React from 'react';
import styled, { css } from 'styled-components';
import introMobileBackground from '../../assets/images/introMobileBackground.jpg';
import introVideoPoster from '../../assets/images/introVideoPoster.jpg';
import problemWeSolveBackground from '../../assets/images/problemWeSolveBackground.jpg';
import sectionSeparator from '../../assets/images/sectionSeparator.svg';
import { AccentedLinkSmall } from '../../components/atoms/AccentedLink';
import ScrollLink from '../../components/atoms/ScrollLink';
import TrustedUs from '../../components/atoms/TrustedUs';
import {
  Header,
  TextMedium,
  Title,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import routes from '../../routes';
import {
  forScreenAtLeast680pxWide,
  forScreenAtMost1024pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import VideoBackground from './VideoBackground';

const IntroSection = (): JSX.Element => {
  const moreAboutUsLink = (
    <MoreAboutUsLink to={routes.aboutUs}>More about us</MoreAboutUsLink>
  );

  return (
    <MainWrapper>
      <VideoBackgroundWrapper>
        <VideoBackground>
          <MainContentColumnWrapper>
            <SloganText>
              Streamlining Antibody Discovery with Data & Machine Learning
              Solutions
            </SloganText>
            <ScrollWrapper>
              <ScrollLink $color="delicate" to="#products-main-page">
                Products
                <AccentedLinkSmall />
              </ScrollLink>
              <MoreLink to={routes.resourcesYear}>Resources</MoreLink>
            </ScrollWrapper>
            <TrustedUsWrapper>
              <TrustedUs />
            </TrustedUsWrapper>
            <SectionSeparator />
          </MainContentColumnWrapper>
        </VideoBackground>
      </VideoBackgroundWrapper>
      <ProblemWeSolveWrapper id="problem-we-solve">
        <MainContentColumnWrapper>
          <ProblemWeSolveInnerWrapper>
            <ProblemWeSolveHeaderSection>
              <Upheader $color="delicateAccented">Problem we solve</Upheader>
              <SectionHeaderParagraph>
                We are facilitating existing therapeutic pipelines in pharma
                companies with advanced bioinformatics solutions.
              </SectionHeaderParagraph>
              {moreAboutUsLink}
            </ProblemWeSolveHeaderSection>
            <TwoColumnText>
              NaturalAntibody provides data and analytics solutions to
              facilitate the process of engineering antibody-based therapeutics.
              We collect, integrate, and repurpose antibody data points across
              public and proprietary repositories to learn about antibody
              structure, function and diversity. We employ this knowledge to
              inform the design of therapeutic antibodies on individual and
              repertoire level.
            </TwoColumnText>
            {moreAboutUsLink}
          </ProblemWeSolveInnerWrapper>
        </MainContentColumnWrapper>
      </ProblemWeSolveWrapper>
    </MainWrapper>
  );
};

const VideoBackgroundWrapper = styled.div`
  position: relative;
  height: 810px;
  background: url('${introVideoPoster}') no-repeat center top;

  ${forScreenAtMost680pxWide(css`
    h1 + a {
      display: none;
    }
  `)}
`;

const SloganText = styled(Title).attrs({ $color: 'primary' })`
  max-width: 531px;
  padding-top: 270px;

  ${forScreenAtMost680pxWide(css`
    max-width: 277px;
    padding-top: 223px;
  `)}
`;

const TrustedUsWrapper = styled.div`
  margin-top: 156px;

  ${forScreenAtMost680pxWide(css`
    margin-top: 96px;
  `)}
`;

const SectionSeparator = styled.div`
  background: url('${sectionSeparator}') center no-repeat;
  height: 84px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -72px;
  z-index: ${({ theme }) => theme.layers.base + 1};
`;

const ProblemWeSolveWrapper = styled.div`
  height: 720px;
  background: url('${problemWeSolveBackground}') no-repeat center top;
  position: relative;

  ${forScreenAtMost680pxWide(css`
    margin-top: 65px;
  `)}

  :after {
    content: '';
    position: absolute;
    width: 44%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0;
  }
`;

const TwoColumnText = styled(TextMedium).attrs({ $color: 'delicate' })`
  columns: 2 255px;
  column-gap: 30px;
  flex-grow: 1;
`;

const MoreAboutUsLink = styled(AccentedLinkSmall)`
  margin-top: 36px;
`;

const ProblemWeSolveInnerWrapper = styled.div`
  max-width: 934px;
  margin: 0 auto;
  padding-top: 157px;

  display: flex;
  gap: 30px;

  ${TwoColumnText} {
    margin-top: 210px;
  }

  ${forScreenAtMost1024pxWide(css`
    flex-direction: column;
    gap: 0;

    ${TwoColumnText} {
      margin: 42px auto 0;
      column-gap: 44px;
      max-width: 560px;
    }
  `)}

  ${forScreenAtMost680pxWide(css`
    padding-top: 57px;

    ${TwoColumnText} {
      margin-top: 22px;
    }
  `)}

  ${forScreenAtLeast680pxWide(css`
    > ${MoreAboutUsLink} {
      display: none;
    }
  `)}
`;

const SectionHeaderParagraph = styled(Header).attrs({ $color: 'primary' })`
  margin-top: 16px;
  width: 354px;
  line-height: 34px;

  ${forScreenAtMost680pxWide(css`
    width: auto;
    max-width: 354px;
    font-size: 22px;
  `)}
`;

const ProblemWeSolveHeaderSection = styled.div`
  ${forScreenAtMost680pxWide(css`
    ${MoreAboutUsLink} {
      display: none;
    }
  `)}
`;

const MainWrapper = styled.div`
  overflow: hidden;

  ${forScreenAtMost680pxWide(css`
    background: url('${introMobileBackground}');
    height: 1530px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    ${VideoBackgroundWrapper}, ${ProblemWeSolveWrapper} {
      background: none;
    }

    ${TrustedUsWrapper} {
      width: calc(100% + 60px);
      position: relative;
      left: -40px;

      ul,
      ${Upheader} {
        padding-left: 30px;
      }
    }

    ${SectionSeparator} {
      position: static;
      margin-top: 70px;
    }
  `)}
`;

const ScrollWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 38px;
`;

const MoreLink = styled(AccentedLinkSmall).attrs({
  $accentColor: 'primary',
  $color: 'delicate',
})`
  font-size: 12px;

  &:hover {
    color: ${({ theme }) => theme.colors.texts.primary};
  }
`;

export default IntroSection;
