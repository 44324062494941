import React from 'react';
import styled, { css } from 'styled-components';
import antiverseLogo from '../../assets/images/trusted-us/antiverse@2x.png';
import astraZenecaLogo from '../../assets/images/trusted-us/astrazeneca.svg';
import gv20Logo from '../../assets/images/trusted-us/gv20@2x.png';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import alchemabLogo from '../../assets/images/trusted-us/alchemab@2x.png'; // TODO: temporary
import aalphabioLogo from '../../assets/images/trusted-us/aalphabio.png';
import bayerLogo from '../../assets/images/trusted-us/bayer.svg';
import orbitalLogo from '../../assets/images/trusted-us/orbital.svg';
import { Upheader } from './Typography/Typography';

const TrustedUs = (): JSX.Element => (
  <TrustedUsWrapper>
    <Upheader $color="delicateAccented">Trusted us</Upheader>
    <Ul>
      <li style={{ position: 'relative', top: 10 }}>
        <LogoWrapper>
          <img height="40" src={aalphabioLogo} width="70" />
        </LogoWrapper>
      </li>
      <li style={{ position: 'relative', top: 5 }}>
        <LogoWrapper>
          <img height="45" src={alchemabLogo} width="101" />
        </LogoWrapper>
      </li>
      <li>
        <LogoWrapper>
          <img height="24" src={antiverseLogo} width="111" />
        </LogoWrapper>
      </li>
      <li>
        <LogoWrapper>
          <img height="33" src={astraZenecaLogo} width="132" />
        </LogoWrapper>
      </li>
      <li style={{ position: 'relative', top: 5 }}>
        <LogoWrapper>
          <img height="45" src={bayerLogo} width="50" />
        </LogoWrapper>
      </li>
      <li style={{ position: 'relative', top: 5 }}>
        <LogoWrapper>
          <img height="39" src={gv20Logo} width="87" />
        </LogoWrapper>
      </li>
      <li style={{ position: 'relative', top: 5 }}>
        <LogoWrapper>
          <img height="50" src={orbitalLogo} width="120" />
        </LogoWrapper>
      </li>
    </Ul>
  </TrustedUsWrapper>
);

export const TrustedUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 40px;
  align-items: baseline;
  flex-wrap: wrap;
  padding-bottom: 5px;

  ${forScreenAtMost680pxWide(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 20px auto 0 0;
    gap: 40px;

    li div {
      display: flex;
      justify-content: center;
    }
  `)}
`;

const LogoWrapper = styled.div.attrs({ $focusBg: 'focus' })`
  opacity: 0.5;

  transition: opacity 0.1s ease-in-out;
  cursor: pointer;

  :hover,
  :focus {
    opacity: 1;
  }

  display: inline-block;
  width: 100%;
  height: 100%;
`;

export default TrustedUs;
