import React from 'react';
import styled, { css } from 'styled-components';
import AbDiagram from '../../assets/images/ab_diagram.svg';
import AnalyticsDiagram from '../../assets/images/analytics_diagram.svg';
import MockupAbStudio from '../../assets/images/mockup_header.png';
import MockupAbStudioMobile from '../../assets/images/abstudio_mobile.png';
import orbits from '../../assets/images/orbits.svg';
import orbits2 from '../../assets/images/bg_circle.svg';
import { AccentedLinkBig } from '../../components/atoms/AccentedLink';
import {
  GradientHeader,
  TextMedium,
  TextSmall,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import routes from '../../routes';
import {
  forScreenAtMost1000pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';

const ProductsSection = (): JSX.Element => {
  const narrowLayout = useMaxWidthBreakpoint(680);

  return (
    <Wrapper>
      <MainContentColumnWrapper>
        <Upheader
          $color="delicateAccented"
          $textAlign="center"
          id="products-main-page"
        >
          PRODUCTS
        </Upheader>
        <AntibodyKnowledgeGraphWrapper>
          <ContentWrapper>
            <SolutionHeader>Antibody Database</SolutionHeader>
            <TextSingleColumn>
              A curated and comprehensive database that integrates antibody data
              points from all major sources, from scientific publications and
              patents to the GenBank sequence database and next-generation
              sequencing repositories.
              <br />
              <br />
              Increase your speed with easy access to antibody data that is no
              longer fragmented or non-standardized. Benefit from
              antibody-specific searches and draw novel conclusions about the
              biology of antibodies thanks to smart data integrations.
            </TextSingleColumn>
            <MoreLink to={routes.antibodyKnowledgeGraph}>
              Discover product
            </MoreLink>
          </ContentWrapper>
          <DiagramWrapper>
            <ImageDecoration height={515} src={AbDiagram} width={518} />
            <LegendWrapper>
              <ItemLegend $color="delicateAccented" rectangleColor="#AAE9C8">
                Sequences search with identity metrics
              </ItemLegend>
              <ItemLegend $color="delicateAccented" rectangleColor="#40656B">
                Text search
              </ItemLegend>
            </LegendWrapper>
          </DiagramWrapper>
        </AntibodyKnowledgeGraphWrapper>
        <AntibodyAnalyticsGraphWrapper>
          <DiagramWrapper>
            <ImageDecoration height={612} src={AnalyticsDiagram} width={430} />
            <AntibodyAnalyticsLegendWrapper>
              <ItemLegend $color="delicateAccented" rectangleColor="#AAE9C8">
                AI suported
              </ItemLegend>
              <ItemLegend $color="delicateAccented" rectangleColor="#40656B">
                Statistical methods
              </ItemLegend>
            </AntibodyAnalyticsLegendWrapper>
          </DiagramWrapper>
          <ContentWrapper>
            <SolutionHeader>Antibody Analytics</SolutionHeader>
            <TextSingleColumn>
              Using data from the Antibody Database, we develop AI models
              connecting the dots and generating insights about antibody
              developability, immunogenicity binding, and other features.
              AI-based analytics helps teams easily discover the advantages of
              individual candidate antibodies and stratify Next-Generation
              Sequencing (NGS) or phage display outputs. By providing
              data-driven insights into antibodies, our rich analytics toolkit
              opens the doors to accelerating therapeutics pipelines.
            </TextSingleColumn>
            <MoreLink to={routes.antibodyAnalytics}>Learn more</MoreLink>
          </ContentWrapper>
        </AntibodyAnalyticsGraphWrapper>
        <NAPlatformWrapper>
          <ContentWrapper>
            <SolutionHeader>NA Platform</SolutionHeader>
            <TextSingleColumn>
              NaturalAntibody is an artificial intelligence-based platform that
              allows in silico discovery and design of antibodies. It combines
              the comprehensive Antibody Database with Antibody Analytics for
              predicting antibody characteristics such as immunogenicity,
              mutability, and developability. Using the Platform, you can
              quickly check if a given sequence was observed in any of the
              covered databases, find a similar sequence with legal protection,
              or leverage antibody data for predictive tasks such as mapping the
              mutational diversity, immunogenicity or developability of
              antibodies.
            </TextSingleColumn>
          </ContentWrapper>
          <AbsoluteImage>
            {narrowLayout ? (
              <ImageDecoration
                height={612}
                src={MockupAbStudioMobile}
                width={430}
              />
            ) : (
              <ImageDecoration height={612} src={MockupAbStudio} width={430} />
            )}
          </AbsoluteImage>
        </NAPlatformWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  overflow: hidden;
  padding: 100px 0 200px 0;

  ${forScreenAtMost680pxWide(css`
    padding: 100px 0 400px 0;
  `)}
`;

const ImageDecoration = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
  z-index: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
`;

const TextSingleColumn = styled(TextMedium).attrs({
  $color: 'delicateStronger',
})`
  max-width: 358px;
  margin: 45px 0 0 20px;
`;

const AntibodyAnalyticsGraphWrapper = styled.div`
  max-width: 940px;
  margin: 0 auto 180px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 150px;

  ${forScreenAtMost680pxWide(css`
    grid-template-columns: 1fr;
    margin: 62px auto 0;
  `)}

  ${forScreenAtMost1000pxWide(css`
    gap: 20px;
  `)}
`;

const AntibodyKnowledgeGraphWrapper = styled(AntibodyAnalyticsGraphWrapper)`
  margin-top: 86px;
  :before {
    content: '';

    position: absolute;
    top: -80px;
    left: -200px;

    width: 409px;
    height: 409px;

    background: url('${orbits}');
  }

  ${forScreenAtMost680pxWide(css`
    grid-template-columns: 1fr;
    margin-top: 57px;

    ${TextSingleColumn} {
      max-width: none;
    }
  `)}

  ${forScreenAtMost1000pxWide(css`
    gap: 50px;
  `)}
`;

const DiagramWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  ${forScreenAtMost680pxWide(css`
    margin-top: 30px;
    order: 2;
  `)}
`;

const NAPlatformWrapper = styled.div`
  margin: 0 auto 50px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  :before {
    content: '';

    position: absolute;
    top: -300px;
    right: -700px;

    width: 100%;
    height: 1068px;

    background: url('${orbits2}');
    z-index: 0;

    ${forScreenAtMost1000pxWide(css`
      right: -400px;
    `)}

    ${forScreenAtMost680pxWide(css`
      display: none;
    `)}
  }

  ${forScreenAtMost680pxWide(css`
    display: flex;
    flex-direction: column-reverse;
    margin: 57px auto 0px;

    :before {
      top: -150px;
      right: -420px;
    }

    ${ImageDecoration} {
      margin-top: 12px;
    }

    ${ContentWrapper} {
      order: 1;
    }
  `)}
`;

const ProductHeader = styled(GradientHeader).attrs({ $gradient: 'primary' })``;

const SolutionHeader = styled(ProductHeader)<{ narrowLayout?: boolean }>`
  max-width: ${({ narrowLayout }) => (narrowLayout ? '200px' : '358px')};
`;

const MoreLink = styled(AccentedLinkBig).attrs({
  $accentColor: 'secondary',
  $color: 'tertiary',
})`
  margin-top: 45px;
`;

const LegendWrapper = styled.div``;

const AntibodyAnalyticsLegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const ItemLegend = styled(TextSmall)<{ rectangleColor: string }>`
  position: relative;
  padding-left: 40px;
  margin-bottom: 5px;

  &:before {
    content: '';
    top: 6px;
    left: 0;
    width: 31px;
    height: 8px;
    background-color: ${({ rectangleColor }) => rectangleColor};
    border-radius: 15px;
    position: absolute;
  }
`;

const AbsoluteImage = styled.div`
  position: absolute;
  right: -342px;
  top: -10px;

  ${forScreenAtMost1000pxWide(css`
    right: -500px;
  `)}

  ${forScreenAtMost680pxWide(css`
    position: static;
  `)}
`;

export default ProductsSection;
