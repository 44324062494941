import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import IntroSection from '../pages-components/index/IntroSection';
import ProductsSection from '../pages-components/index/ProductsSection';

const IndexPage = (): JSX.Element => {
  return (
    <Layout
      description="Discover the world of AI bioinformatics solutions that facilitate antibody engineering for therapeutic purposes - the future of antibody-based drug discovery."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="AI Computational Solutions for Antibody Engineering"
    >
      <IntroSection />
      <ProductsSection />
    </Layout>
  );
};

export default IndexPage;
