import React from 'react';
import styled, { css } from 'styled-components';
import homeIntroVideo from '../../assets/videos/homeIntroVideo.mp4';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

interface VideoBackgroundProps {
  children: React.ReactNode;
}

const VideoBackground = ({ children }: VideoBackgroundProps): JSX.Element => {
  return (
    <Wrapper>
      <VideoWrapper>
        <Video autoPlay loop muted src={homeIntroVideo} />
      </VideoWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${forScreenAtMost680pxWide(css`
    display: none;
  `)}
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export default VideoBackground;
